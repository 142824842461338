import { useState } from "react";
import Link from "next/link";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Avatar from "./avatar";
import EnrollmentModal from "./enrollmentmodal";
import EnrollmentBtnTooltip from "./enrollmentbtntooltip";
import PriceInfoPopover from "./priceinfopopover";

import { getParticipantAvatarClassName, urlToMedia } from "../lib/content";
import WinnerExperienceGain from "./winnerexperiencegain";
import ParticipantCount from "./participantcount";
import PlacesLeftInfo from "./placesleftinfo";
import GamePlace from "./gameplace";
import EnrollmentCard from "./enrollmentcard";
import ScenarioRating from "./scenariorating";
import HostGrade from "./hostgrade";
import PlayerPeerRating from "./playerpeerrating";
import GameExtraInfoPoints from "./gameextrainfopoints";
import GameCharacteristics from "./gamecharacteristics";
import GameLevel from "./gamelevel";
import ThumbnailPicture from "./thumbnailpicture";
import { isMeet4USite } from "../lib/coreutils";

function EnrollmentIconBtn({ game, onClick }) {
    const img =
        game.is_enrollment_prohibited && game.prohibition_reason ? (
            <img src={urlToMedia(game.prohibition_reason.icon)} className="icon" alt="+" />
        ) : (
            <img src="/img/icons/icon-plus-lg.svg" className="icon" alt="+" width={26} height={26} />
        );

    return (
        <EnrollmentBtnTooltip game={game} showAlways>
            <a
                href="#"
                onClick={onClick}
                className={
                    "schedule__participants__btn-enroll " +
                    (game.is_enrollment_prohibited && !game.is_waiting_list
                        ? "schedule__participants__btn-enroll--prohibited"
                        : "")
                }
            >
                {img}
            </a>
        </EnrollmentBtnTooltip>
    );
}

function EnrollmentBtn({ game, onClick }) {
    const btn = (
        <a
            href="#"
            onClick={onClick}
            className={
                "btn btn-lg my-1 schedule__participants__btn-enroll--text " +
                (game.is_enrollment_prohibited && !game.is_waiting_list ? "btn-orange" : "btn-primary")
            }
        >
            Забронировать место
        </a>
    );
    return <EnrollmentBtnTooltip game={game}>{btn}</EnrollmentBtnTooltip>;
}

export default function ScheduleTableRow({
    game,
    showPlacesLeftInfo,
    isArchive,
    isExpandableEnrollment,
    isExpandedEnrollment,
    onEnrollment,
}) {
    const participantList = game.participant_list;
    const hasParticipants = participantList.length > 0;
    const [showEnrollment, setShowEnrollment] = useState(false);
    const [showEnrollmentCard, setShowEnrollmentCard] = useState(isExpandedEnrollment);
    const [featureCollapsed, setFeatureCollapsed] = useState(true);

    const showEnrollmentModal = (event) => {
        event.preventDefault();

        if (game?.with_teams) {
            window.location.href = game.url;
            return;
        }

        if (event.target.blur) {
            event.target.blur();
        }
        setShowEnrollment(true);
    };

    const expandEnrollmentCard = () => {
        setShowEnrollmentCard(true);
    };

    const collapseEnrollmentCard = () => {
        setShowEnrollmentCard(false);
    };

    const [date, time] = game.start_time_long.split(" в ");

    if (showEnrollmentCard || isExpandedEnrollment) {
        return (
            <tr>
                <td colSpan={6} className="py-3">
                    <EnrollmentCard
                        game={game}
                        onEnrollment={onEnrollment}
                        onCollapse={isExpandedEnrollment ? null : collapseEnrollmentCard}
                        showPlacesLeftInfo={showPlacesLeftInfo}
                    />
                </td>
            </tr>
        );
    }

    const priceInfoPopover = <PriceInfoPopover game={game} />;

    return (
        <>
            <tr className="schedule__table__tr">
                <td>
                    {date}
                    <br />
                    {time}
                </td>
                <td>
                    {game.host && (
                        <div className="schedule__host">
                            <Avatar player={game.host} size={66} className="schedule__host__avatar" />
                            <div className="text-start ms-2">
                                <Link href={game.host.url}>
                                    <a className="schedule__host__name">{game.host.name}</a>
                                </Link>
                                <PlayerPeerRating
                                    player={game.host}
                                    noDecoration
                                    className="schedule__host__grade d-block"
                                />
                                {game.organizer && <div className="schedule__host__name text-muted">Ведущий</div>}
                                {game.club && (
                                    <div className="schedule__host__name d-flex align-items-center my-1">
                                        <ThumbnailPicture
                                            thumbnail={game.club.thumbnail}
                                            width={20}
                                            height={20}
                                            alt={game.club.name}
                                            className="rounded-circle"
                                        />
                                        <div className="ms-1 text-muted">{game.club.name}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {game.organizer && (
                        <div className="schedule__host mt-1">
                            <Avatar player={game.organizer} size={66} className="schedule__host__avatar" />
                            <div className="text-start ms-2">
                                <Link href={game.organizer.url}>
                                    <a className="schedule__host__name">{game.organizer.name}</a>
                                </Link>
                                <PlayerPeerRating
                                    player={game.organizer}
                                    noDecoration
                                    className="schedule__host__grade d-block"
                                />
                                <div className="schedule__host__name text-muted">Организатор</div>
                            </div>
                        </div>
                    )}
                </td>
                <td>
                    <Link href={game.url}>
                        <a className="schedule__game-link">{game.game_type.name}</a>
                    </Link>
                    <GameLevel gameLevel={game.game_level} className="mx-auto mt-2" />
                    <HostGrade hostGrade={game.host_grade} className="mx-auto mt-2 d-none" levelOnly />
                </td>
                <td>
                    <GamePlace place={game.place} />
                </td>
                {!isArchive && (
                    <td className="text-nowrap">
                        {game.game_price_amount && (
                            <>
                                <span className="schedule__price">{game.game_price_amount}</span>{" "}
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={priceInfoPopover}>
                                    <a tabIndex="0" className="info-button" role="button">
                                        ?
                                    </a>
                                </OverlayTrigger>
                            </>
                        )}
                        <GameExtraInfoPoints game={game} className="my-1" />
                    </td>
                )}
                <td>
                    <div className="schedule__participation-details">
                        {!isMeet4USite() && (game.feature || game.characteristics || game.thomases_scenario) && (
                            <div className="text-center text-muted mt-n3 pt-1 small">
                                <GameCharacteristics game={game} className="text-body" />
                                {game.thomases_scenario && (
                                    <div className="text-primary">
                                        Сценарий &laquo;{game.thomases_scenario.name}&raquo;
                                        <ScenarioRating scenario={game.thomases_scenario} />
                                    </div>
                                )}
                                {game.feature && (
                                    <div
                                        className={
                                            "schedule__participation-feature" +
                                            (featureCollapsed ? " schedule__participation-feature--collapsed" : "")
                                        }
                                        onClick={() => setFeatureCollapsed(!featureCollapsed)}
                                    >
                                        {game.feature}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="schedule__participation-numbers mb-1">
                            {!isMeet4USite() && (
                                <>
                                    <WinnerExperienceGain game={game} className="text-center small flex-grow-1" />
                                    {game.teams_info && game.teams_info.num_teams > 1 && (
                                        <div className="text-center small flex-grow-1">
                                            Уже {game.teams_info.num_teams_str}
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="schedule__participation-count">
                                <ParticipantCount game={game} />
                            </div>
                        </div>
                    </div>
                    <div className={"schedule__participants" + (isArchive ? " schedule__participants--archive" : "")}>
                        {hasParticipants ? (
                            <>
                                {participantList.map((participant) => (
                                    <Avatar
                                        player={participant.player}
                                        size={66}
                                        className={getParticipantAvatarClassName(participant)}
                                        key={participant.player.id}
                                    />
                                ))}
                                {!isArchive && <EnrollmentIconBtn game={game} onClick={showEnrollmentModal} />}
                            </>
                        ) : (
                            !isArchive && <EnrollmentBtn game={game} onClick={showEnrollmentModal} />
                        )}
                    </div>
                    {showPlacesLeftInfo && <PlacesLeftInfo game={game} className="text-center mt-n1 pt-1 mb-1 small" />}
                    {showEnrollment && (
                        <EnrollmentModal
                            game={game}
                            onHide={() => setShowEnrollment(false)}
                            onEnrollment={onEnrollment}
                        />
                    )}
                </td>
            </tr>
            {isExpandableEnrollment && (
                <tr>
                    <td colSpan={6} className="text-center">
                        <button className="schedule__expand-link small" onClick={expandEnrollmentCard}>
                            Развернуть
                        </button>
                    </td>
                </tr>
            )}
        </>
    );
}
